<template>
  <v-container fluid>
    <loading-overlay :loading="loading" />
    <v-card tile flat>
      <skeleton-page-heading v-if="data_vendors.length === 0" />
      <v-card-text v-else>
        <breadcrumb-component />
        <v-divider class="tw-mb-5"></v-divider>
        <h2
          :class="
            $vuetify.breakpoint.mdAndUp
              ? 'tw-text-gray-800 tw-text-xl tw-flex tw-font-medium tw-capitalize'
              : 'tw-text-gray-800 tw-text-base tw-flex tw-font-medium tw-capitalize'
          "
        >
          <v-icon class="tw-text-gray-800 tw-mr-2"
            >mdi-account-edit-outline</v-icon
          >
          {{ title }}
        </h2>
        <v-divider class="tw-mt-5"></v-divider>
      </v-card-text>
      <v-card-text>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="12" md="7" lg="7">
            <skeleton-form :count="6" v-if="data_vendors.length === 0" />
            <v-form v-else @submit.prevent="submit">
              <v-text-field
                v-model="form.name"
                label="Nama"
                placeholder="Nama"
                dense
                outlined
                autofocus
                :error-messages="errors.name"
                :disabled="loading"
              ></v-text-field>
              <!-- form vendor if admin -->
              <v-autocomplete
                v-if="role === 1"
                dense
                outlined
                item-text="user.name"
                item-value="id"
                label="Vendor"
                :disabled="loading"
                :items="data_vendors"
                :error-messages="errors.vendor"
                v-model="form.vendor_id"
                @change="getProjects(form.vendor_id)"
              ></v-autocomplete>
              <!-- end -->
              <!-- form vendor if developer -->
              <v-select
                v-if="role === 2"
                dense
                outlined
                readonly
                label="Vendor"
                :items="data_vendors"
                item-text="user.name"
                item-value="id"
                :disabled="loading"
                v-model="form.vendor_id"
              ></v-select>
              <!-- end -->
              <v-autocomplete
                dense
                outlined
                item-text="name"
                item-value="id"
                label="Project"
                :disabled="loading || data_projects.length === 0"
                :items="data_projects"
                :error-messages="errors.project"
                v-model="form.project_id"
              ></v-autocomplete>
              <v-text-field
                v-model="form.surface_area"
                label="Luas Permukaan"
                placeholder="Luas Permukaan"
                dense
                outlined
                :error-messages="errors.surface_area"
                :disabled="loading"
              ></v-text-field>
              <v-text-field
                v-model="form.building_area"
                label="Luas Bangunan"
                placeholder="Luas Bangunan"
                dense
                outlined
                :error-messages="errors.building_area"
                :disabled="loading"
              ></v-text-field>
              <v-text-field
                v-model="form.price"
                label="Harga"
                placeholder="Harga"
                dense
                outlined
                prepend-inner-icon="mdi-currency-usd"
                :error-messages="errors.price"
                :disabled="loading"
              ></v-text-field>
              <v-file-input
                v-model="optionalData.brochure"
                outlined
                dense
                prepend-icon=""
                prepend-inner-icon="mdi-file"
                label="Brosur"
                :error-messages="errors.brochure"
              ></v-file-input>

              <v-file-input
                v-model="optionalData.thumbnail"
                outlined
                dense
                prepend-icon=""
                prepend-inner-icon="mdi-image"
                label="thumbnail"
                :error-messages="errors.thumbnail"
              ></v-file-input>

              <div class="tw-flex tw-max-w-full">
                 <div class="tw-mb-6 tw-w-1/2 tw-mr-2">
                  <label class="tw-text-gray-900">Jumlah Kamar Tidur</label>
                  <number-input
                    class="tw-mt-1"
                    v-model="form.bedroom"
                    :min="0"
                    :max="10"
                    controls
                  ></number-input>
                </div>
                <div class="tw-mb-6 tw-w-1/2 tw-ml-2">
                  <label class="tw-text-gray-900">Jumlah Kamar Mandi</label>
                  <number-input
                    class="tw-mt-1"
                    v-model="form.bathroom"
                    :min="0"
                    :max="10"
                    controls
                  ></number-input>
                </div>
              </div>

              <div>
                <label
                  :class="
                    errors.description ? 'tw-text-red-500' : 'tw-text-gray-600'
                  "
                  >Deskripsi</label
                >
                <text-editor v-model="form.description" class="tw-my-2" />
                <v-alert
                  dense
                  border="left"
                  type="error"
                  v-if="errors.description"
                >
                  {{ errors.description[0] }}
                </v-alert>
              </div>
              <div class="tw-text-right">
                <v-btn
                  depressed
                  class="tw-capitalize tw-tracking-wide"
                  color="primary"
                  max-width="200"
                  width="120"
                  type="submit"
                  :loading="loading"
                  >Submit</v-btn
                >
              </div>
            </v-form>
          </v-col>
          <v-col cols="5" v-if="$vuetify.breakpoint.mdAndUp">
            <v-skeleton-loader
              v-if="data_vendors.length === 0"
              class="mx-auto"
              width="250"
              type="image"
            ></v-skeleton-loader>
            <v-img
              v-else
              contain
              max-height="250"
              src="https://ik.imagekit.io/5zmdzllq3/editable_Dibch8QTjKc.svg"
            ></v-img>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import form from "@/mixins/form";
import page from "../mixins/page";
import Swal from "sweetalert2";
import { detail, update, vendors, project } from "@/api/propertyDetail";
import BreadcrumbComponent from "../components/BreadcrumbComponent.vue";
import SkeletonPageHeading from "../components/SkeletonPageHeading.vue";
import SkeletonForm from "../components/SkeletonForm.vue";
import LoadingOverlay from "../components/LoadingOverlay.vue";
import TextEditor from "../components/TextEditor.vue";

export default {
  components: {
    BreadcrumbComponent,
    SkeletonPageHeading,
    SkeletonForm,
    LoadingOverlay,
    TextEditor,
  },
  mixins: [form, page],
  data() {
    return {
      datas: "",
      data_vendors: [],
      data_projects: [],
      optionalData: {
        brochure: [],
        thumbnail: []
      },
    };
  },
  computed: {
    form: {
      get() {
        return this.datas;
      },
    },
    role() {
      return parseInt(this.$store.state.auth.profile.role_id);
    },
  },
  async created() {
    await this.getDetail();
    await this.getVendors();
    await this.getProjects(parseInt(this.form.vendor_id));
  },
  methods: {
    async submit() {
      try {
        this.$store.dispatch("auth/setLoading");
        await update(this.form, this.optionalData);
        this.$store.dispatch("auth/setLoading");
        this.errors = {};
        this.$router.push({ name: "property-detail" });
      } catch (error) {
        this.$store.dispatch("auth/setLoading");
        if (error.response.status === 403) {
          Swal.fire({
            icon: "error",
            title: error.response.data.message,
            timer: 1500,
          });
        } else if (error.response.status === 422) {
          this.errors = error.response.data.errors;
        }
      }
    },
    async getDetail() {
      const {
        data: { data },
      } = await detail(parseInt(this.$route.params.id));
      data.vendor_id = parseInt(data.vendor_id);
      data.project_id = parseInt(data.project_id);
      const bathroom = parseInt(data.bathroom)
      const bedroom = parseInt(data.bedroom)
      this.datas = data;
      this.datas.bathroom = bathroom
      this.datas.bedroom = bedroom
    },
    async getVendors() {
      const {
        data: { data },
      } = await vendors();
      this.data_vendors = data;
    },
    async getProjects(id) {
      const {
        data: { data },
      } = await project(id);
      if (data.length === 0) {
        this.data_projects = [];
        Swal.fire({
          icon: "error",
          title: "Data terkait tidak di temukan",
          timer: 1000,
        });
      } else {
        this.data_projects = data;
      }
    },
  },
};
</script>

<style scoped>
::v-deep .number-input__input {
  text-align: center !important;
}
</style>
